import axios from 'axios'
import {obtenerApiKeyInterno, obtenerApiUrl} from '../config'

let restApi = null

const initializeRestApi = async (configApiKey = false) => {
  const apiConfig = await obtenerApiUrl()
  const apiKey = await obtenerApiKeyInterno()
  restApi = axios.create({
    baseURL: apiConfig,
    headers: {
      'R-Authorization': configApiKey ? apiKey : 'true',
    },
  })
}

const getRestApi = async (configApiKey = false) => {
  await initializeRestApi(configApiKey)
  return restApi
}

export let idToken = '...'

export {restApi, getRestApi}
