import React, {useContext, useEffect, useState} from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BarraDeNavegacion from './componentes/BarraDeNavegacion'
import {BrowserRouter} from 'react-router-dom'
import Router from './Router'
import ModalDinamico from './componentes/Modales/ModalDinamico'
import {ContextoAplicacion} from './contexto/ContextoAplicacion'
import Spinner from './componentes/Spinner'
import {obtenerVersionApp} from './config'

const App = () => {
  const [numeroVersion, setNumeroVersion] = useState('Cargando versión...')

  useEffect(() => {
    obtenerVersionApp()
      .then((version) => {
        setNumeroVersion(`4.0.${version}`)
      })
      .catch((error) => {
        console.error('Error al obtener la versión de la aplicación:', error)
        setNumeroVersion('Error al cargar la versión')
      })
  }, [])

  const contextoAplicacion = useContext(ContextoAplicacion)

  return (
    <>
      <BrowserRouter>
        <ModalDinamico />
        {contextoAplicacion.cargando ? <Spinner /> : null}
        <div className=" w-full min-h-screen sticky top-0 z-50">
          <BarraDeNavegacion />

          <Router />
          <div className="text-cendiatra fixed text-sm bottom-0 right-0 p-2">
            {' '}
            {numeroVersion}
          </div>
        </div>
      </BrowserRouter>
    </>
  )
}

export default App
