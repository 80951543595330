import RedesSociales from './RedesSociales'
import {TEXTO_VISUAL} from '../../constantes'
import {OpcionLateral} from './opcionLateral/OpcionLateral'
import Instagram from '../../imagenes/redesSociales/instagram.svg'
import Facebook from '../../imagenes/redesSociales/facebook.svg'
import Youtube from '../../imagenes/redesSociales/Youtube.svg'

export const BarraLateral = () => {
  const redesSociales = [
    {
      nombre: 'Facebook',
      enlace: '/',
      logo: Facebook,
    },
    {
      nombre: 'Instagram',
      enlace: '/',
      logo: Instagram,
    },
    {
      nombre: 'Instagram',
      enlace: '/',
      logo: Youtube,
    },
  ]

  const downloadPdf = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div className="w-18% h-full flex flex-wrap justify-center items-center content-between rounded-lg bg-cendiatra-gris-5 shadow-xl px-6">
      <div className="w-10/12 flex flex-wrap justify-center items-start text-start  mt-38px">
        <span className="w-full text-cendiatra-verde-2 text-17px font-helveticaNegrita  border-gray-500 pb-16px uppercase">
          {TEXTO_VISUAL.HOME.LATERAL.BIENVENIDA}
        </span>

        <div className="border-b border-cendiatra-gris-4 w-full pt-5 "></div>

        <div className="w-full flex justify-center items-center py-2">
          <OpcionLateral
            texto={TEXTO_VISUAL.HOME.LATERAL.OPCIONES.DESCARGAR_LISTADO_IPS}
            onOpcionClick={()=> downloadPdf("https://prodcendiatra.blob.core.windows.net/listados-dimar/IPS.pdf")}
          />
        </div>
        <div className="border-b border-cendiatra-gris-4 w-full"></div>
        <div className="w-full flex justify-center items-center py-2">
          <OpcionLateral
            texto={TEXTO_VISUAL.HOME.LATERAL.OPCIONES.DESCARGAR_LISTADO_MEDICOS}
            onOpcionClick={()=>downloadPdf("https://prodcendiatra.blob.core.windows.net/listados-dimar/MEDICOS.pdf")}
          />
        </div>
        <div className="border-b border-cendiatra-gris-4 w-full"></div>
      </div>

      <RedesSociales
        estilosContenedor={
          'w-full flex flex-wrap font-bold justify-start items-center pb-2'
        }
        titulo={TEXTO_VISUAL.HOME.LATERAL.SIGUENOS}
        redes={redesSociales}
      />
    </div>
  )
}
